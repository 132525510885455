import React, { Component } from 'react';
import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Dashboard}/>
          </Switch>
        </BrowserRouter>
    );
  }
}

export default App;
