import React from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ScrollArea from 'react-scrollbar';
import Typography from "@material-ui/core/Typography";
import amber from "@material-ui/core/colors/amber";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import CopyIcon from "@material-ui/core/SvgIcon/SvgIcon";
import CardMedia from "@material-ui/core/CardMedia";

const styles = theme => ({
    root: {
        marginTop: 10,
        // marginLeft:15,
        marginBottom: 10,
        marginLeft: 5,
        marginRight: 5,
        flexGrow: 1,
        width: "100%",

    },
    alertList: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginRight: 10,
        //overflowY: 'scroll',
        //maxHeight: 300,
        minWidth: 250
    },
    title: {
        margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        margin: 0,
    },
    bigAvatar: {
        width: 60,
        height: 60,
    },
    instructions: {
        color: '#FFF',
        marginTop: 5,
        paddingBottom: 5,
        paddingTop: 10,
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        marginTop: 5,
        paddingBottom: 5,
        paddingTop: 10,
        marginBottom: 5,
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4CAF50',
            main: '#4CAF50',
            dark: '#388E3C',
            textColor: '#fff',
            contrastText: '#fff',
        },
        secondary: {
            light: amber[500],
            main: amber[500],
            dark: amber[700],
            contrastText: '#000',
        },
    }
});

class InteractiveList extends React.Component {
    state = {
        dense: true,
        secondary: true,
    };

    render () {
        const {classes} = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <ScrollArea style={ {width: '100%',
                    maxHeight: '50%'} } horizontal={false}>
                    <div className={classes.alertList}>
                        <Card className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography variant="h5" component="h5" textSecondary>
                                            Install or Enable Price Guru Chrome Extension
                                        </Typography>
                                        <Typography component="subtitle1">
                                            Start Tracking your Favorite Products
                                        </Typography>
                                    </CardContent>
                                    <div className={classes.controls}>
                                        <IconButton aria-label="Copy">
                                            <CopyIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                                <CardMedia
                                    className={classes.cover}
                                    image=""
                                    title="Live from space album cover"
                                />
                            </Card>
                    </div>
                </ScrollArea>
            </MuiThemeProvider>
        );
    }
}

InteractiveList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(InteractiveList));