import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SignInModal from './SignInModal'
import {withRouter} from "react-router-dom";
import InteractiveList from "./InteractiveList";
import InteractiveCards from "./InteractiveCards";
import Grid from "@material-ui/core/Grid/Grid";
import Avatar from "@material-ui/core/Avatar/Avatar";
import CouponCards from "./CouponCards";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AlertIcon from '@material-ui/icons/NotificationsRounded';
import PeopleIcon from '@material-ui/icons/Share';
import CouponIcon from '@material-ui/icons/LocalOffer';
import ReportBugIcon from '@material-ui/icons/BugReport';
import AutoBuyIcon from '@material-ui/icons/FlashAuto';
import TutorialIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: '#4CAF50'
    },
    settingsbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: '#ffe11b'
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    leftTitle: {
        padding: '1em',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
});

class Dashboard extends React.Component {
    state = {
        menu: 0,
        open: true,
        isSignedIn: false, // Local signed-in state.
        email: 'Not Logged In',
        username: 'Name',
        profilePic: ''
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    render () {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <CssBaseline />
                <div className={classes.root}>
                    <AppBar
                        position="absolute"
                        className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden,
                                )}
                            >
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="42px" height="42px" viewBox="0 0 42 42" enableBackground="new 0 0 42 42"><image id="image0" width="42" height="42" x="0" y="0"
                                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqEAYAAACVUxUYAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                                                                    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0T///////8JWPfcAAAA
                                                                    B3RJTUUH4goaBzsSKAg3hgAAJAJJREFUaN7NmWdcVNfat/9r76kwMIP03rugiAI2FAW7xt577yUm
                                                                    JibWqLEktojd2HvvYBcsoKIUFRCRzlAGGNowde/1fjDnOed5zvueJ6fknPf6uMtv7XWte9/3Kiz+
                                                                    TawQf9fzOx+gfkztSbUv4FnqrnB7LM01OyRdK9/Ti8g1lkaF8ts9Lm6OpVZ041k71lpgdfXHLNtZ
                                                                    1iGK+vX9I+QhXaw2rRnbbntQhs2drwqtR1oxrYJGjxQdF8dZf+t70qqnYoKDTF0ZZh26L+hoVbjt
                                                                    eJsqe0uaWX67Iqs85d/VS4D5oxsYXDzw2KAjwJ5H8aN25wC4hTW0q/94VU6NVX3Afn8bjZzT1Zyp
                                                                    Hl3T5wb1nRr+U+yX08icoIYhuh5jYZDbKsItWtNlrMJcJdVATOrbTQkcTiNkt1ZOnGmJO6FbF3qN
                                                                    ceWnfDnPU+q0Rzf5qqzUs6ydcviaJYZf9D10vM3DAL1vqN82YGn3xe+WXP7jhZLf+6Ag3Sx03JeA
                                                                    KUxrG3oZMTJXS+2FpWYHpN+JvXSr7F1EFoLvua12u4SWwvWM0uIdWYQBSGY+oTd86MrmicI1Akfe
                                                                    qJjLWXLjDYnrN4S/C/yZzgr75rv6GScET4CQx77jmI3QMRsEHeEBSdOZpgt0ParqLjacwEzkGdKM
                                                                    ZUiHOA9FR/kXaKraXLcUvZHf7Uz7hwyP0IbUpgV0MTpubnW4mNtHt6fb50aS3dd6ieeJR4j6LD9i
                                                                    5m3e29yyOoqdwIxnFupTHW85DXZitOMve14LubqdD3u14uXRl9uBDusjJkcs+QOEBtoHTIkYBTAl
                                                                    go/i7cx3TaUN02pnB7exzpZvN64fYunx2PEBPRxT5lxlxyPBm7G0kq3BJ8UmLMRUuAtvNsla+tMD
                                                                    JK2qqVZD25rmlyVWHqaNsFT1Ud+kI83P917TKYqJQd0sixEKdhgcA3/1cmWGw5edy2bDBWzLSt1P
                                                                    9BLUqt51tuiPmuIG5TjKoK6wsvw8bYIu/VSuNT8fqvxJJS/pe4glh8QO6AMHZefqROqDIOUb1UyM
                                                                    orFkBFYQWVkkWcdsJGvq3YicFEDQeJqtYg4Rp7KdbBObxKS9n8gOZ6eyPVK+lmRItJKKN/FpMzIc
                                                                    0iPq6od2+oL54jpqrqRcp9e/+DuEvlVkRmZFAcMmDvUcsRkQDBaWi9tb1dISrod23cLKKP/Qi3zH
                                                                    mZlDUnrMIz2dgtulBsqYAQi1ViuOYS8gPC24QCIAcoPsggDgxvKZVAVoc3WLcRuoflR3lPZF07u4
                                                                    fBU9h+xHD1+68UoYPl4rDqMZ4MOHBk1gzoBYtDevwWyYMjd8SOFXQ/JpXdmPtBg2dT/U38dsyOk0
                                                                    rIQJvNVmy3PYhEbHZzZLyRs02plaBZDrqFdUWjbiZxjFT0W/IAoR+v6GFUiEQr25cTumQVBxQCWh
                                                                    7QHlHFUZ7Qo0bml2xnOAdqKrcUm7SGAuOM+8fPtSMEjwhaD6WLL0rdRMypwd1zS5KbypT92FgVMG
                                                                    TRo0AtiWtvPMjlt/Q2jrmkDboGCAPCezBFrbicb+Bn9Nys79wz/E/YrvRj1ZlD1uL+vAxNkMa2Uk
                                                                    qQDG0xjUA1RLBdACeEBfgQfAgQcF4A5HMAB2kK8gAIiEtIYMQBcSAnNQ4z7j13iBxlyHwvf8bRSf
                                                                    Z+7wXBc03PjqsZFfD4/GPc012Alr33buCaQtSqM2hn5iUlEeGRi6kTwD7zfE/QQTDWvbeqv+uAQr
                                                                    sx8ln8hAyASnWQZuEKGaJEECKVVRV2ihNV3k5uMT9M1XNO/ofjSWbK+8ReWoe3Hp7XC+A7R3w58P
                                                                    52+jzbuMj+vpdsiNDaYGLOK+F74TrmQf3u8i7imeJB68jFXVq9arNmb1bruj7YK2i4FEen/mvVl/
                                                                    ITRiU7vi8CzAbIb5WrM6gam0f+mtisaft3R3aG/BJy8y/PztV6MEkVitIBYishXg2/D9oAaIDJYA
                                                                    AAlEAEAb0Py7kgwHDgDIMBIDBiCnSQ2sAZOa24tCNKd+kXmJD8SHs0cSP3Eh+DSRDtzPimETcS7k
                                                                    DvMInbCRDIUFxHhD86ABoVJaAQ2AafgBRgDv6EfwALTQAwDMIQEARJJQMABJw1mIAVQz82EJIANm
                                                                    AIzqg/UpdDaq7+5NucWnovTIy6sJXC688lYUdaAZsGMTBWuZMZkzJQvFy8Ts9PcNXRpUDfZpz73X
                                                                    e/t5S4HH2U+nPVkIEL8CL5V3JcAQdqvwTEx7kZkgXi+/fHXX9eXugk0K5+jp7XcybgDfyElRApBJ
                                                                    5CsIAVwktZDCiEk0FjrwWI3xEEBMXX6LWDGkv0uw6TfBg0h3MABJZAbCHrT8XVUedUTRp/zSl7QU
                                                                    yZHVIRPIY/QVJQrvklDY0Yv03uc3/0kIKAAQA2mAFCBi5gcowBV1KYuiKhRv23I81jQKLQmznkTy
                                                                    p9Aal4mQGZx5RHpcekFiNfl9y5GWRS3bMrZ2tYteGF0HMIfeHvnx8CukG8wMX5jKRlh4u7tIYK5w
                                                                    blPnd4fEo5yW8u9QDZDN5GsIAf0LQyV9ioZj86/Gc9V4uXHDoc4mR2RWamrCaEdUMFLGCbYAeBh+
                                                                    V4cEYAGA3qZPwAO8kVuMChDnGXafSB4822uCW5ML6ISx6A0WzdDB8FnBvwj6Oe1RAZVDC1Ahtx0V
                                                                    YD2eOo8hCnitc1twQNAVdiMP9VnJPEcW3U7H85fbTNEF62x1S3Ycsxhv8bPFWkfRa480VdosgFm6
                                                                    drF8yT2bJOrGt+LFEZO961wvEgAW3uZ3yDzIEUZHwACQvkQBc0DVue4DRqDgYPWljaYkmJ9S3nrB
                                                                    OUG3kTuUanLDq6IB5fdoAwoZAdsFdgCxgwIEAP2dGtjPKYRP4u+jGZBmiCUkFr6iPsIpJAhe9Cp9
                                                                    CP5fKPTPkfpZrP7zd/ItfDNqAHmWzJJ8C7ulBZOiBAmwi84Pt2SaAGO0aS8/tdtLnVBXp6ua9U3W
                                                                    5ezt7+Phx+jvGI4YDtutQwwk1MrhuFOobQpJB5hh7A44QkyrUQcK0Eu0EhrATm/tj5vw6iwKS2JK
                                                                    YEQ2CgBY1ExVg45C8eEuV7y5AjzP7JIr5JciHa5wAmAio0lfsPivHPq7I3czPQwTQJPpa/B/vv6H
                                                                    I/mcsvgYfgrqgVZjFJfJITjMfzWmK7sKObanrTSIhadRZIw0LR43u61X6+Eh/QMSBaIykVZYK/Ul
                                                                    3zBDSbiok9leyTXsBwC44tWfZwF0M/0VJkBcKDpKukE+x2sUETTBuuxS1W3jbVyts6hvizmwNTQY
                                                                    s+lrmL1/lT+Sfo1n7A1Gw5/B+4BMzxtMLPqz4UwQ7GBFM/DhD4m0fzW/1QLamT+HKqDND/5rmE1w
                                                                    ixvUkWEyoD2ddnsav9kr1LDXcN6wYWALY7xjOGlM1E2jL2gZXWCs1G01VOIVAOAGuL/4TQUQAADv
                                                                    +3nEPIXO84kVPBccH5MjWI2u7o+do4gAWu1sfTluAdnaAlf+Cnz0uwylSAalHXARHCqxgiyEAIAR
                                                                    pv+0q78H+pimgQfYBYJ0+MI87suO+9koZEudxAuQx9RwV7j7/JC44YwwSZgnfKnqgo30KXmmulj+
                                                                    sXo3dQP4a9wAlENPHGD9P9ZTn39Ff94BtSBRS9okMVkI6/u2SwQzFuFsZyYbtiA+KrdzTABarnV4
                                                                    VMV/Ddnj4S+7c0o0GA+a3uE9tMyvzEEoACL+bT/h9+bY/xTsb6kmmf6KRsB7umsI8YKZzZcKG2wB
                                                                    uBh+FX8qOJnttSjuYC9vbXHp85KW0h0d0mlHytGTYaRPUpcqZhBUZnUSR5IJSzobDIx/0cBc1AEA
                                                                    2UJmggfjp/V4xCRAUbW4NgNPUJ1+PqcrPxvSEc97xbORkBcuKPfAt6hLt8ytojV4b31PXkvsIZR/
                                                                    ZZFKKBSkhuSCBQN3tAUHgP3jN27+ITzgBALwHfgozEX+zeTkb/kf4VrTXb0AQ8QGtnF//YL6KzRP
                                                                    zIl1ohrGqH7YeJxbMHhngL1nGzJeMMv/qpeYeQdKjVSIRhAwv43Ub1URfTEYFGByiBZmELZ29hnF
                                                                    VMKu1KXSQONRe93rUQ9+JfhRr3vHME/h5HXdZRSzD7aZE/NW8j8ipSlLEwwZ3lndt5iNDbAQ7RaG
                                                                    k/OwwkzMAwX5/y1ymRjiDhHQ3KOlAa5ouJh8F3w6GmrvNHyiOYyQkTspJiuGAOadzb3Msh646Xca
                                                                    gxifx4uPjLoq4JRAxepqMfVBGSNiamENQA/tf2vhT9V4Al0AAyCoY+vhBtm0CUP3CFojtMO84OHk
                                                                    KqQrJLtkpifQ1IY33KAzwA46H8OwazDKva3TM0IRolXqv0MCnlA/OhAGNBDz37kw+Hfxp4F9zARA
                                                                    BlS510bQWBhrXerH0oHwFXRkRUyzNpjxWe3j7x0PqNuoNepBjXOklyX9RCWbJFmP85yhqzr2084j
                                                                    l00ecFU7NfanC1HJSNnusAOIHOL/1pAQQgCg3egg6AHBAXYFPCGb9njYE0F7BPYt7hrGjoFoVVX8
                                                                    BFM6dJcL7vXnhiCnVbNCSI7Cz2aU1R5yCZNxBMshgII2QfOfdviXEMF/VZKOYGBK6ZvlxofCUN/Q
                                                                    dA9rAfKA7CAJSnM2+0nO65zDQND9gE4BfkDs6F6DY7OKNn3s//F5/veaIblVBXJubfdzhUy5ib4T
                                                                    fek/0P0Fk4o8a1crlvjCknxDosGAxTw6AwYA3/82GXKDLziAvc1chhVEbaz8PzAECqmjeCp6oTp+
                                                                    39k53GaIy55V2lMbKAM8PJtJHDiLdrLHRANLrMM16EAQhe7g8eei8O/G+LlyMEOYPVAAymJVGg1A
                                                                    +c8rjrTmHkBUva2uAkq0Er4WBLJX713/r48srS4/V3YNMN7RTdf3wk8KgWKv4kpGhjZIG66zaxyf
                                                                    90tRNVffse/TjDfBvEqyjE/hlahHnuM+W2cyBBqLfea3SBXMyCpGCRuwRECiIAFIOBkHKcB0YSbA
                                                                    AYLgXN9yRg1rr9fOFUSCqpNDb17iZBDe06fI+DjorH6weISVKHXpbH+YzIe5sI1oEbkOCWZiH7QA
                                                                    +mAoKP74ovWbSJJB7kEMGKWm4chF4y6H08kcB+X9zJRY/jGCGBdmOtJ0w4Xhon6iiK1v/2rUy7UV
                                                                    ryqyAAcnuyF23/K7bOJst1kvTTupm6JdpR2Qs7N2Y/0Z3hgU/jQy/SfO0673k2uvR3KRJLSmq7oO
                                                                    hXiFX2lbuKCkeWlLA92PnMp+tcG0M0o/9SzpS9+iKP159jF+PApKGioC6TrU1IY2aOhMVLxKfAfa
                                                                    Fx0fTXh1n6+CPF9WEkLfo6DVj5bmRIIquwLrApIIM8FUYR5aQ4JfkAA9gHB0Aw+A+RcJNn3eg2B6
                                                                    MdGQAdxt/gnK0Hx84PXWXBOyDq66dInrAT+TknNGBiRsL7YLM+HFNtkK83bm/ht+/V+PQAal9z8+
                                                                    YDZwPezWxJv7gLCXoUxogXuz5njzas27I9XCCNEP4vcxnqY5xiFGS1QIfmILjSZwgm7sN3QtqvjX
                                                                    fDh9Dplhk/EcXsFSf834Cm9hzk3mGFTAjKThLCwhIHeZeXAB8AtOwhvgN/HPMBC0lUGeyfhD1V3U
                                                                    IZdZgpLhfNw49i5IeFDQWnIY7lKTdDAZj1Z4Qa2gBUPz6HE0AphPt8AIUDUa/1b/SBv4ggDEmVHA
                                                                    HEAiuQwr8HUV9Y10MpRHf7raniuD8sgvV/dyhfDRZOl24xBasXGMgli2rBPNEW0VbZtq08A33G1I
                                                                    Ojf3d58phT9pQ9s2Avo++n76dTYPxUZxsqjT3fjRwtGGkZvCLrWUaYN1Rbh55/7tDbc8IQuf7peo
                                                                    6AtWXCXaILBCo76V4ajJFTqTht9KFyKQXqRqOgr3eT9+PfVDa+Mc0wZ+FrQtnK7eOBpm9WFNfXQn
                                                                    YVFlU7ul5Sns635pcNNPgpPc0mIuUaOu/fvgg4wDCuPWddzNtEVz+Jig6eQUFM4T7ZJJHuzMmiSx
                                                                    ZCgUpJgdBQeIAXwLIRgARlAAOgyCHpxJZRIgF5raAPVROhGq1OlZCj4S1WeLEjtzrSFNq3hP6VAE
                                                                    8aV8ND5CSjaRifiFFwvGCOcJF+3oaLvO5qPNteU/6w36Qn2VocPvFhrE+Dn42wD6L/Tj9Hs7PQgY
                                                                    EdDOvzLRccrIqZsmRVkEJi15+OODULwI6+tsp40AO/5Wv01BHHwF09gdjAcEtD3dSgPRZPqZi+Kn
                                                                    A/pXhnbcaRRqluk2GZ0glL4S2wjsYcPomHiiAqu9pFtjOg9tmbh6VZMJTefu3N2ZmweaMP7ZgwIn
                                                                    eKinNO7SfwEbUajQkkSjxXa+1QciQLlHP+fzRIpqz3DnWcQSTY75tj+SdJgsDprNJ3NRSmUIhxZs
                                                                    fWhjC10L5+KEimBqhDhndsEKegO2xXEVBygHX8NSQzy9CzNmK+NOggFmGjORBDc8FXwU1Auq9k43
                                                                    z5IxMv3Gr01mRo3R2Dg9k3//+l3e3zikCzgR4TpsP5A74WXppVnAVPuR34TIgfuXni5WTxwwpOuD
                                                                    rlc6v7jM+f3iW+6dLrxmcxtXP23B4wkR/ZwCdWjzqbzsQ30DysvXVBU2dUBl6Ziq5qaroCWxlbGN
                                                                    18CV7Koc2jgE5vQ4raET0bT2+9ltunSCj9cDZ2/FNfjRd1SOrwEMIcPQFi36WYZjXClUrzvmjKhU
                                                                    oers2zsWOTz0z5VZFuVr4N5wsemEfhJcuTv8cHQB4U/RcnoSYAaRViQWJiaa3McQGKgXtaJSMDiM
                                                                    9yQVIvQjk3EfDLObDCPBANORqcEP/FXyBelD2OqVjDsTyIQkeQorhBCaDvkqEhUmRVXSPn2J/pE+
                                                                    zdiSlpCxMP3bP3v7q6I0asPw7iN8AOXb/O3pLwH7WXasfd+Qw8Ji4md2YPMWi0TzILExto96UdMp
                                                                    XQ/Pnt71NmNNP7PcTMXgTYEJcNaV61M5Fk1n1Xe8c8Q49qFz8fO6BpA6ZeMNrRps7YX6am0N0HxB
                                                                    S4zbYNP5SZupLvsh6T6ufYqbGj7MGWYBkULINJEgEgKauOQ5X/Adnh1RX1/11gml+lrDXM6IGsub
                                                                    MpXYGdnqkY1pulhU1ejrldrZ8HB/6DDWUolS13EOyRYeeGvZbN5G1Bpa2pbkC06j2e6w3VnblXBg
                                                                    LjKTicJwgldzrU2aR7HCk8L1gnNXU4V2Qm8hv3+DuEZsJ5ZuXGe1xUplVXv4sHGFab1pw8eH4VHh
                                                                    P4f/xBtvLLh98Vbi/yUn/88LA9Anv+9N4CYSfVSHkN0tMuKMsd/+E9+fmbYr0n3mj9Jw0TbBY9Tu
                                                                    9Dnz6XU8MlftnZnUaR9cgjO9frRxgh/nzd+kF0FpHF1KdajOHVcUWBeEvMy7BebNk/GATqML8AP0
                                                                    YXe9O8nUGBiQ6+lqfQVhZBxxxgVIcY0m4SPAz6C3aA80fLVox61H9nh55cnDfR890VXyWGzPdoBK
                                                                    MdtigKQCDV55ziMUGmh7Du8w3u0DdLHpUWM9eNjKz8vSxe9A6nMad+mmoeWA322b8tV4GDgyfFN3
                                                                    NyxN/DHB6k7s8+4pmpS4F1FfPNdt1xHtmBrjphlbEracAqY+mP7rtAd/x+zgNwSTvhy/Y8JUZJn8
                                                                    TXYmZ4RWLq1QVQgANAMl80Q2HvFOzgFJHifbDfM/bc/hxwarZq2+LVT9Vnep8dqAWn9r9x9ajUEX
                                                                    fjmvpbsA1ol5T3iQp+uz2lUko2F/WsIZpQL1czLmVi19ge7yIPkFeV/Inw5IGHIU+ODc2e4bfQL8
                                                                    Lb+Q1YsDIMV5GJALYBWmkigwHcKD1jlkIq30ddXC5mjcMOzhvmTjUdu9fbudjiYETFraf2RQNMbY
                                                                    ZFi5S8vhQY00BOshQA59jBRA3CSMYQ6g1IqRm5kloI16SN2T+tvcjIrtlQOr+h9PCZOElYRG1hhp
                                                                    PpsmSQamPphe84+I/BNM+pE3p99kRex6fPRhv4dfAZI6qUha/vlmdZxRyo/glTRPtcyoN+n4a0D2
                                                                    vcLntan44JfoNrbVL7AVBgnGMo0QYTjpCX9A20F309AZ9PqHnDumS3B6LfTpHHMdcXmnldLKj+gY
                                                                    omqzunVvhIV6Rd7sPQV9arMa9+urISNdSCNZApBuhCX7gLoLjUFaX1jkDiwtaC7C3LjAAR9GT8Sk
                                                                    Ldt3Bh9Mx0L7rKB2fQsR+ub4h52qTSjFTroL+yDg/fl11AegZuhEtwG0nPpjDwj3mvPlH6H9u8qs
                                                                    5ekvjGeNz1vC1fKeJz4t+GRR+Dhi7lur9MVpr4GkI4+fPl7wTwg1VBtfGR5uipJ9tFhksdc7pOhE
                                                                    YUqhGIipjjgRHEstCuYqVzTeTrqQOezj9urtvK52QL2b9i28XBMdnC3Gw4EcIv3IA4C1YRYxX8BU
                                                                    69cg196FxtHKOsNlCsx9prfZGHYCkozFJZ+UqyEGaEeMBSzeWkRYvIICl0k7hoeYdEMwpIDJjRvM
                                                                    zQKOVt73KVYB5w+KnnToAXmFmWCMZSasgr4JrAswIaTzu+jbPR/BK+du6QBtWyhNyzgV/wAmYgkZ
                                                                    xABGIg4BgHG66QB/Gkz1mKrdVeVYMyy00w3LK5LzK9dO39Kx+4iRtoXy/aLTU04UtL8xuacZsPHp
                                                                    hmEbvvwnhLadH5ocuqyDeYdv268KLz1YJXYXx4gNndo+sns54f19YVoeLVleX3Pj/aXhD8s/Hnu6
                                                                    oVhWUdPQFiGNL5qb9SmoTq58U1r6Ak/2WF+Mz2Rx8aeUk90zqpH4cMY97eNL0FWLj93ZngiUdE7j
                                                                    U7oCLxJTm18MBfIHvP/4ciOgGGp+j80CmJVMKzIGKLWrHNBgBqgP6CzkFUAH8972/acADzdWomEJ
                                                                    uJpRNUTVA7XGQGO6sR5Eb2PYY1wJMcqghyMomM8bi4QlGeRboF7f3FlfgLoQeLnLShEctz5igesM
                                                                    9PTr6d621QXAokQ2Wpxr3KvXqs8LNgBm16SFZnX/uFCBfIjcR363wq3ntz2+63Yppip4SHBQoPO1
                                                                    zNang9VBy15/XXy7xKFkTuHh11tzC+q86YOPP5S90cjo4eqftENk5wmVzVTstNci32yojPqxkHjs
                                                                    DVlt3hG2QZ7SOkl71NO1nN5YBXH1IJW0NgzMHrv4Y7tuAGH93OZy9uCtoltnt54E8tYj/xuVJ+gl
                                                                    i8fGYilI/dcaB0s7EPGyR+cvGgDzBGWzUgtdEpN0LPkU0qpdqu+p9LB2SrPqwSbDQrhEcJNJB2uq
                                                                    5MLpMYB1Igr8CpSmVWU2/YzigHnuLdaecBXeF+1g4yH62FISqV5v+rJar76n65VyIxZfxafIAeNi
                                                                    JEjMwXa6FbEoohYxzF02lX2OZJsCm+427WG42ubG5huOf0No8cES55KWzGLjOZOTKdu3Y8RPHRra
                                                                    z7dp45fi29/Xuvfdop4lytKhgMarpVPLOTiLoyQlEhFWKa7L7ykM4KTuZqGShagXfSPUClejRFDI
                                                                    6tmHCBT1EiYINsNO+lzyTFKCbgGJgXuZCNi289IkaebAlNT+fp/bvyLr7Mc717NNaHnu8cFe9waW
                                                                    IR/Cv4p+ACa0yl5ptwKppmzjOIMLWL3GP8t9OzwKdPmnP0RD/EKV6vziHfjBN6IOyYfD3DjMNJNf
                                                                    DL1wneAtmwWpvsUwwtQamoJ+ZZn1uVD1GdZpnOe36Jc9Iz+0xgy49ODh4I+Tky8ViJXtmhPvujje
                                                                    bFVmtgj5fB/at+Wu/252vuArgZtVuwyLt4VZdakR4xJGDxoT+jsitGxXOVP+9rYh1yb3Yt7ygUft
                                                                    R9tV2c0QT5Ytk3nLpqPFT+170ecOyvlZtJLfg2LTQ9Nzrg9smQ/MRGYoHAUNAn82AK3gCSN2Q8fU
                                                                    keHMePgIGgWP2N2Ioy/pJ+oOOdeO68oNBmTLzYvMvwOvb83fEl6C7tCam83Zd9EyccTE05O9Ye0m
                                                                    c7vgehOpTa80RzSHMJsbjRByDT2YNJG76C4iXO3cHrp1gIdNoQ1juwnCNx8yd7+2RLEy52xl1gUo
                                                                    ux1oM92uAW6VW2ovaKZAnOdWvKMuF06m4ZyOb9HsvPMkxVB0Ktn6dW7u19Vbt7g7GW2SZeY+o8wL
                                                                    zOeYxX/pXNe1zlf9bnA7Poh3531Xj/UReZR5ZgBOt506OLn8Zi3r/y2UuCU5D3FJtdsbRALNAk5c
                                                                    ODH+2dilo1uinzksdHCw/xKgA2kfOgRAMSpRDY5OocvpBhgwD0PRCxIEYAgmg5jGcbbcKIAxkt3M
                                                                    YoBpZnaSJQCxIZQ0AUwdU8HkgS/KLooqZpF39OBxxakPqLExs3lvPRdNs0wz5k+dhkjGghEyWrCm
                                                                    qVw/7gTWGaYbV5taoOYf8TJ+IlyNg03bTfdhqfXQHtG9hcmQYlAYvoO4bk2tuu4gmmqrq/eVfwe9
                                                                    MkZ5RrkCEZU9qsW17XCb+Z65IvxVmeOw3HGDw+r8HKc4x4kOLy1n2/a0abCe4XWzsqIqqDrF8vX9
                                                                    cQ+mP4y93oMHv5M2jd8sfCU8LJzXFPG6InNvxoX/PUKJt9o93SMZEDQJ+wknRze1n9ru6zD+0LI+
                                                                    vr1940J999j3tG+0Gwaww5mBTDRAX+IV0gDcxG0kAnCGA+wBEk824QcAMehF+gJ8LD+RXwuDZp3m
                                                                    qCYftdk7cwJzY1CUl/Cx9hNFU3Rotxkx+9H07MbTb5IjoOt7p7cqNgPR8tvy7fIouNI3NJcWgeMq
                                                                    +Q18It7QRXQdPYBGE8895aPAQEEjaC+8oAcpR2+gizZed1bnj3qBjyBV8AQayZdic9EPiDS0M1gb
                                                                    hJBiE11NF8NO5CQiogwQSbxkuaQXULGlsrhyE3BecSHm8qE323Kbcu/l6Sb0Jg3kFn7Kbh2ByN6R
                                                                    CuDkiDOi0xG/pyhNkLvJOwJvbmZVZBYkt2Ik5CoZPmxH89vmmc1d5sT71fid8d3YW22ntquxbeei
                                                                    lLGyLNlJ0RZhoFAtfAULTKbj6RWUtWzVhuvuIqdhX8PGhlJoqnSVPhUXoCs8WPR9STcoLbtYsJY5
                                                                    eDMhZsrbqc/RPy62d9dea9BdoBZuE05E9puV6XaZMcjvPrjbF12HwZacJefIKUjYfkwCswYdUAMD
                                                                    igBhB3YM+wagOchANqLJBDKSXAFrNlU6VOINin14j1cgGIRmrAQkYrFBzANkDSklFQA3mBvLzQPy
                                                                    6/Pff0qD/u69e+oHOc8+fZB++Jh3Y763qbtpp3FHdmufJN9Tvr2Bk/FnBp7+O44J/2rpKV0r3i5R
                                                                    Ajp//VX9Uba1+0OXu673XPKtJll9UswMXiVRSW5IFvqCJJFIonR9ZslK36BxlHcPTft7Lo4uO22X
                                                                    KMaKt+Okvdh6nnQO7B9de2pZ9CtSXb5RqXUroaQSSz+5Et30kW6HfXORb/VNq0OtEmFMK8vvW8jC
                                                                    SvZCvszyAEI6JURtizgGf/k1ebA8Cg7MbGYgcYaAnqDH6DEAr5GOdAD+8IMfQCaQcWQMAAIGBODW
                                                                    cJu4/YCmlSayZRVQnq4MViqB7A7ZaTm1mmfZ73JW5V47c7VkVClX1rAuQDRC+EI4v2S6WVfzDFkY
                                                                    8GZFpnV66u8X+f8U+r8R2SO8oIMcOFi66IzPBmDJhX36D7vnrF68dExSWMz2RQMyu8737iW2whuE
                                                                    ow1QkFA2uX4M8h8kXevz9h0eKRTV65sCwTHR+qHGiQB9gOHkIwyv7wsaJXPxQhUqCWPuo5sgXhxk
                                                                    cRRyi2OtypwLwdnG2o9ycISnxWLZHNkIRLAhbDs2Aix3nrvJpQCaZy2VLa2BOnndQPULoPJsJa06
                                                                    aVqgNFWMr7CoWqcqUIWr3qdsbQprWtusOdaxJU+7TNt0f5xAx54R/KyrL95X7lC66+8X+E8L/RN+
                                                                    g3wDg9YDpXlV1zWjpYvi+PYGe8vNxbPChopCv5+XFrs80ui+mSkVFgjAjgaavm9xMMSgtsKsStP0
                                                                    AFma5Y3ttVuRx//ER9AWqK2WWeeYf4fODr3sHlp0QZvm5y3lhrOwzH1QtKM2GEgxe9tScZjuvzMs
                                                                    dVupOndn4/GWJK76yUw6n/rRi6YnRgdjmHG41mCcZdxsvFcVYDpnemqqzs+jC+gvuPReID4lrhFb
                                                                    FplrLmsCNErDid4+A6oG6YCD1gfC9hj/UQv/QqF/wvOqxym/aKD5ptbGdMXSEDrK+6rCbbloUGS0
                                                                    o1fMLGMvaZSfh9ZK4LLHzt/iISDuLQK7EiCdyEYMAEzgknlnQDNK62IsB1QeakftUBSVelfVNRlb
                                                                    jhesKttXn5udkLOlyEH98qJ3RteP62v8z91KkqfufONRFN5OFJrZNhoP3hiy2mQk/+vE/MeE/gnr
                                                                    03ZjvZYDzYG6d7xGZOYVbb9THB17NyDO43vF4Gk9vVOdjyh6R1VaTDJfLdxhe582UkfMJodaAnTj
                                                                    TR4NkerNjZ663oVrVXz9Ym3oq24Vppp4bft7EwuMSp+G2c+oSl1dXRxf/RqWcBMtxz40osSw8T+t
                                                                    76/5P0RXlTdbhYflAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4LTEwLTI2VDE0OjU5OjE4LTA3OjAw
                                                                    Rc8inwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0xMC0yNlQxNDo1OToxOC0wNzowMDSSmiMAAAAA
                                                                    SUVORK5CYII=" />
                                </svg>
                            </IconButton>
                            <Typography variant="title" color="inherit" noWrap className={classes.title}>
                                Price Guru
                            </Typography>
                            <Grid container xs={4} alignItems="flex-end" direction="row" justify="flex-end" >
                                <Typography variant="caption" color="inherit" className={classes.leftTitle}>
                                    {this.state.email}
                                </Typography>
                                <Avatar
                                    alt="Product Image" src={this.state.profilePic}
                                    imgProps={{style: {'object-fit': 'contain'}}}
                                >
                                </Avatar>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            <ListItem button>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <AlertIcon />
                                </ListItemIcon>
                                <ListItemText primary="Alerts" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <CouponIcon />
                                </ListItemIcon>
                                <ListItemText primary="Coupons" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <AutoBuyIcon />
                                </ListItemIcon>
                                <ListItemText primary="AutoBuy" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListSubheader inset>Contact Us</ListSubheader>
                            <ListItem button>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Share" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText primary="Info" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <TutorialIcon />
                                </ListItemIcon>
                                <ListItemText primary="Tutorial" />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <ReportBugIcon />
                                </ListItemIcon>
                                <ListItemText primary="Report Bug" />
                            </ListItem>
                        </List>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        {this.contentPage()}
                    </main>
                </div>
            </React.Fragment>
        );
    }

    contentPage () {
        switch (this.state.menu) {
        case 0:
            return <div>
                <Typography variant="h5" gutterBottom>
                    Your Alert List
                </Typography>
                <div>
                    <InteractiveList/>
                </div>
                {!this.state.isSignedIn
                    ?<div>
                        <SignInModal/>
                    </div>
                    :<div/>
                }
                <Typography variant="h5" gutterBottom>
                    Popular Products
                </Typography>
                <div>
                    <InteractiveCards/>
                </div>
                <Grid container direction="row" alignItems="center" justify="space-between">
                    <Grid item alignItems="flex-start" justify="flex-start">
                        <Typography variant="h5" gutterBottom >
                            Coupons & Deals
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" gutterBottom onClick={()=>{ this.setState({menu: 2}) }}>
                            See More
                        </Typography>
                    </Grid>
                </Grid>
                <div>
                    <CouponCards/>
                </div>
            </div>;
        default:
            return null;
        }
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const DashboardWithRouter = withRouter(Dashboard);
export default withStyles(styles)(DashboardWithRouter);