import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button'
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Chrome from '../../chrome.png'

function getModalStyle () {

    return {
        position: 'fixed',
        top: `35%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    };
}


const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '40%',
        backgroundColor: '#4CAF50',
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        borderRadius: 8,
    },
    avatar:{
        margin: 4,
        backgroundColor: theme.palette.background.paper,

    }
});

class SignInModal extends React.Component {
    state = {
        open: true,
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    componentDidMount() {
        this.handleOpen()
    }

    render () {
        const {classes} = this.props;

        return (
            <div>
                {this.state.open ?
                    <Modal
                        open={this.state.open}
                    >
                        <div style={getModalStyle()} className={classes.paper}>
                            <Grid xs={12} container alignItems="center" direction="column" justify="center">
                                <img style={{maxWidth: '100%', maxHeight: '100%'}} src={'https://firebasestorage.googleapis.com/v0/b/dukaanbabu-3a466.appspot.com/o/slie1.png?alt=media&token=9614c0ee-c7eb-4d4a-b8db-6f60cd4ace2b'}></img>
                                <Button variant={'filled'} className={classes.avatar} style={{marginTop:10}} onClick={()=>window.open('https://chrome.google.com/webstore/detail/price-guru-track-prices-i/eohjdjcndckfpafmjgbhpjfemadonfii','_blank')}>
                                    <Avatar alt="Chrome" src={Chrome} className={classes.avatar} />
                                    Add to Chrome
                                </Button>
                                <Typography variant="subtitle1" gutterBottom>
                                </Typography>
                            </Grid>
                        </div>
                    </Modal>
                    :
                    <Modal
                        open={true}
                    >
                        <div style={getModalStyle()} className={classes.paper}>
                            <Grid xs={12} container alignItems="center" direction="column" justify="center">
                                <Typography variant="h6">
                                    Price Guru
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    You will get Price Alerts to your Email
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    <a href={'https://www.youtube.com/watch?v=asIfxq_H1cE'}>Tutorial - How to Set Price Alerts</a>
                                </Typography>
                                <img width={'90%'} src="https://price.guru/img/tut.gif" alt="loading..." />
                                <Typography variant="subtitle2" gutterBottom>
                                    Access the App from Popup Button in the Toolbar
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    Dashboard is Coming Soon.
                                </Typography>
                            </Grid>
                        </div>
                    </Modal>
                }
            </div>
        );
    }
}

SignInModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignInModal);