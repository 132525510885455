import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import ScrollArea from "react-scrollbar";
import {
    List,
    ListItem,
} from '@material-ui/core';
import amber from "@material-ui/core/colors/amber";

const styles = theme => ({
    icon: {
        marginRight: theme.spacing.unit * 2,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(theme.spacing.unit * 3 * 2)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    cardGrid: {
        padding: `${theme.spacing.unit * 4}px 0`,
    },
    card: {
        height: '300px',
        width: '25%',
        minWidth: '200px',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        height: '100px', // 16:9
        objectFit: 'contain',
        backgroundSize: 'contain',
        backgroundPosition: 'center 40%',
    },
    cardContent: {
        flexGrow: 1,
    },
});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4CAF50',
            main: '#4CAF50',
            dark: '#388E3C',
            textColor: '#fff',
            contrastText: '#fff',
        },
        secondary: {
            light: amber[500],
            main: amber[500],
            dark: amber[700],
            contrastText: '#000',
        },
    }
});

const cards = [{
    "imageUrl" : "https://images-na.ssl-images-amazon.com/images/I/819yQy4vYUL._SL1500_.jpg",
    "name" : "Logitech MK550 Wireless Wave Keyboard and Mouse Combo",
    "url" : "https://www.amazon.com/Logitech-MK550-Wireless-Keyboard-Mouse/dp/B003VAHYNC/ref=pd_ybh_a_5?_encoding=UTF8&psc=1&refRID=QMRXCTQ2RA2WGZZTM5KM"
}, {
    "imageUrl" : "https://images-na.ssl-images-amazon.com/images/I/51ZfpSi62iL._SL1500_.jpg",
    "name" : "NETGEAR Orbi Ultra-Performance Whole Home Mesh WiFi System",
    "url" : "https://www.amazon.com/NETGEAR-Orbi-Ultra-Performance-Whole-System/dp/B01K4CZOBS/ref=pd_ybh_a_7?_encoding=UTF8&psc=1&refRID=QMRXCTQ2RA2WGZZTM5KM"
}, {
    "imageUrl" : "https://images-na.ssl-images-amazon.com/images/I/6182S7MYC2L._SL1000_.jpg",
    "name" : "Echo Dot (3rd Gen)",
    "url" : "https://www.amazon.com/All-new-Echo-Dot-3rd-Gen/dp/B0792KTHKJ/ref=pd_ybh_a_8?_encoding=UTF8&psc=1&refRID=QMRXCTQ2RA2WGZZTM5KM"
} , {
    "imageUrl" : "https://rukminim1.flixcart.com/image/832/832/j58hj0w0/headphone/extra-bass/k/y/y/sony-mdr-xb450-original-imaevftnddaejzaw.jpeg?q=70",
    "name" : "Sony XB450 Earphones",
    "url" : "https://www.flipkart.com/sony-xb450-wired-headphone/p/itmf3vhftu8ehews?pid=ACCDZRE8DWQWJKYY&lid=LSTACCDZRE8DWQWJKYYERYMRD&marketplace=FLIPKART&srno=b_1_2&otracker=hp_omu_Deals%2Bof%2Bthe%2BDay_1_KW84S2CG81VY_0&fm=neo%2Fmerchandising&iid=7a659950-09fb-47e2-b46d-589270cd5d0e.ACCDZRE8DWQWJKYY.SEARCH&ppt=StoreBrowse&ppn=Store&ssid=xjr8coo5n40000001544926112334&affid=harrsha7g"
}, {
    "imageUrl" : "https://rukminim1.flixcart.com/image/832/832/jg8ahzk0/fabric/f/s/c/maisha-rudra-fashion-original-imaf4b4yffzrn6ut.jpeg?q=70",
    "name" : "Fab Anarkali Gown ",
    "url" : "https://www.flipkart.com/siddeshwary-fab-anarkali-gown/p/itmf4by7nsyfqkmg?pid=GWNF4BXBFKTUMFZF&lid=LSTGWNF4BXBFKTUMFZFOCJGJV&marketplace=FLIPKART&srno=b_1_23&otracker=hp_omu_Fashion%2Bfor%2BTravel%2BLovers_3_93CMIKHQ0UBW_1&fm=neo%2Fmerchandising&iid=641f1e1e-4c21-49fd-b835-e444b0e6e122.GWNF4BXBFKTUMFZF.SEARCH&ppt=StoreBrowse&ppn=Store&ssid=31545dwec00000001544926223832&affid=harrsha7g"
} , {
    "imageUrl" : "https://images-na.ssl-images-amazon.com/images/I/61KB8FQ2-7L._SL1500_.jpg",
    "name" : "Tagg Sports Plus Bluetooth Earphones With Mic",
    "url" : "https://www.amazon.in/Tagg-Sports-Bluetooth-Earphones-Black/dp/B075JHC4M2?pd_rd_wg=e479I&pd_rd_r=3b557e1d-7f69-462b-bfb7-ce63eb9dcd61&pd_rd_w=CDBFD&ref_=pd_gw_simh&pf_rd_r=CSZK1PT3ETBZMECPVC3T&pf_rd_p=c2ebdb65-3bcd-59df-a62a-6b0e925df1af&tag=affidtrack01-21"
}, {
    "imageUrl" : "https://images-na.ssl-images-amazon.com/images/I/81SgDtCe2zL._SL1500_.jpg",
    "name" : "Redmi 6 Pro ",
    "url" : "https://www.amazon.in/Redmi-Pro-Gold-32GB-Storage/dp/B07DJHV6S7?pd_rd_w=rmp4K&pf_rd_p=516f6605-f925-4b03-af43-931a2a1d5e70&pf_rd_r=87da9fe9-8347-4ab2-ac42-2e27c947b811&pd_rd_r=87da9fe9-8347-4ab2-ac42-2e27c947b811&pd_rd_wg=oumQK&ref_=pd_gw_unk&tag=affidtrack01-21"
}, {
    "imageUrl" : "https://images-na.ssl-images-amazon.com/images/I/51EfDWKl24L._SL1300_.jpg",
    "name" : "OnePlus 6T",
    "url" : "https://www.amazon.in/Redmi-Pro-Gold-32GB-Storage/dp/B07DJHV6S7?pd_rd_w=rmp4K&pf_rd_p=516f6605-f925-4b03-af43-931a2a1d5e70&pf_rd_r=87da9fe9-8347-4ab2-ac42-2e27c947b811&pd_rd_r=87da9fe9-8347-4ab2-ac42-2e27c947b811&pd_rd_wg=oumQK&ref_=pd_gw_unk&tag=affidtrack01-21"
} ]

/**
 * @return {boolean}
 */
function InteractiveCards (props) {
    const {classes} = props;

    const flexContainer = {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    };

    return (
        <MuiThemeProvider theme={theme}>
            <React.Fragment>
                <main>
                    <div className={classNames(classes.layout, classes.cardGrid)}>
                        <ScrollArea
                            horizontal={true}
                        >
                            <List style={flexContainer}>
                                {cards.map(card =>
                                    <ListItem>
                                        <Card className={classes.card}>
                                            <CardMedia
                                                className={classes.cardMedia}
                                                image={card.imageUrl}
                                                title={card.name}
                                            />
                                            <CardContent className={classes.cardContent}>
                                                <Typography gutterBottom variant="subtitle2" component="subtitle2">
                                                    {card.name}
                                                </Typography>
                                            </CardContent>
                                                <Button size="small" color="primary" variant={"contained"}>
                                                    Buy
                                                </Button>
                                                <Button size="small" color="secondary" variant={"outlined"}>
                                                    Track
                                                </Button>
                                        </Card>
                                    </ListItem>
                                )}
                            </List>
                        </ScrollArea>
                    </div>
                </main>
            </React.Fragment>
        </MuiThemeProvider>
    );
}

InteractiveCards.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InteractiveCards);