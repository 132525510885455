import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import ScrollArea from "react-scrollbar";
import {
    List,
    ListItem,
} from '@material-ui/core';

const styles = theme => ({
    icon: {
        marginRight: theme.spacing.unit * 2,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(theme.spacing.unit * 3 * 2)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    cardGrid: {
        padding: `${theme.spacing.unit * 4}px 0`,
    },
    card: {
        height: 'auto',
        width: '50%',
        minWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
});

const cards =[ {
    "name" : "Upto 30% off on Salon Grooming",
    "url" : "https://linksredirect.com/?pub_id=29906CL26957&url=https://www.amazon.in/b%3Fie=UTF8%26node=15682732031%26pf_rd_p=6cd881b4-8b9d-40de-b0aa-87e3b3084a04%26pf_rd_r=FKR3QK39KVQRCZP0DVXH"
}, {
    "name" : " upto 84% off on North Star women sneakers",
    "url" : "https://linksredirect.com/?pub_id=29906CL26957&url=https://www.amazon.in/s/ref=sr_st_price-asc-rank%3Ffst=as%253Aoff%26rh=n%253A1571283031%252Cn%253A%25211571284031%252Cn%253A1983396031%252Cn%253A1983578031%252Cn%253A9780815031%252Cn%253A1983640031%252Cp_89%253ANorth%2520Star%26qid=1542291134%26bbn=1983640031%26sort=price-asc-rank"
}, {
    "code" : "WELCOME401",
    "name" : "51% Discount Until 499"
}, {
    "name" : "Upto 70% off on Personal Accessories",
    "url" : "https://linksredirect.com/?pub_id=29906CL26957&url=https://www.jabong.com/personal-accessories-catalog"
}, {
    "name" : "Buy 3 Items for 939 Only",
    "url" : "https://linksredirect.com/?pub_id=29906CL26957&url=https://www.jabong.com/bundlebuy394916nov%3Fcmpgp=16112018buy3at949_1542292741519\""
}, {
    "code" : "MYNTRANEWW500",
    "name" : "Rs. 500 off Above Rs1499"
}, {
    "name" : "Upto 50% off on Gap",
    "url" : "https://linksredirect.com/?pub_id=29906CL26957&url=https://www.myntra.com/gap%3Ff=gender%253Amen"
} ]

/**
 * @return {boolean}
 */
function CouponCards (props) {
    const {classes} = props;

    const flexContainer = {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    };

    return (
        <React.Fragment>
            <main>
                <div className={classNames(classes.layout)}>
                    <ScrollArea
                        horizontal={true}
                    >
                        <List style={flexContainer}>
                            {cards.map(card =>
                                <ListItem>
                                    <Card className={classes.card} style={{backgroundColor: '#e4bd49', color: '#FFFFFF'}}>
                                        <div className={classes.details}>
                                            <CardContent className={classes.content}>
                                                <Typography component="h5" variant="h5" >
                                                    {card.name}
                                                </Typography>
                                                <Typography variant="subtitle1" color="textSecondary">
                                                    {card.code? card.code:'NO CODE'}
                                                </Typography>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </ListItem>
                            )}
                        </List>
                    </ScrollArea>
                </div>
            </main>
        </React.Fragment>
    );
}

CouponCards.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CouponCards);